export const Projects = [

    {

        "title": "Tasky",
        "description": "Taks app on the Rinkeby blockchain",
        "demoLink": "https://tasky.jhongarces.dev",
        "sourceCode": "https://github.com/jairogm/tasky-app",
        "borderColor": "linear-gradient(90deg, rgba(216,180,254,1) 0%, rgba(129,140,248,1) 100%)"

    },

    // {
    //     "title": "Cuacane",
    //     "description": "Forecast app dashboard",
    //     "demoLink": "https://www.example.com",
    //     "sourceCode": "https://www.github.com",
    //     "borderColor": "linear-gradient(90deg, rgba(110,231,183,1) 0%, rgba(59,130,246,1) 50%, rgba(147,51,234,1) 100%)"
    // },
    // {
    //     "title": "Simple CRUD",
    //     "description": "brief description of the project",
    //     "demoLink": "https://www.example.com",
    //     "sourceCode": "https://www.github.com",
    //     "borderColor": " linear-gradient(90deg, rgba(253,230,138,1) 0%, rgba(252,165,165,1) 50%, rgba(254,202,202,1) 100%)"

    // },
    // {
    //     "title": "Pinterest Clone",
    //     "description": "brief description of the project",
    //     "demoLink": "https://www.example.com",
    //     "sourceCode": "https://www.github.com",
    //     "borderColor": "linear-gradient(90deg, rgba(216,180,254,1) 0%, rgba(129,140,248,1) 100%)"
    // },
    // {
    //     "title": "Radio App",
    //     "description": "brief description of the project",
    //     "demoLink": "https://www.example.com",
    //     "sourceCode": "https://www.github.com",
    //     "borderColor": "linear-gradient(90deg, rgba(110,231,183,1) 0%, rgba(59,130,246,1) 50%, rgba(147,51,234,1) 100%)"
    // },

]