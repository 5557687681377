import React from 'react'
import {  Footer } from '../Components'

export  function Blog() {
  return (
    <>
     
      <hr />
      <Footer />
    </>
  )
}
